@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: PPAgrandir;
  src: url("fonts/PPAgrandir/PPAgrandir-GrandHeavy.otf");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: GeistMono;
  src: url("fonts/GiestMono/GeistMono-Regular.otf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: GeistMono;
  src: url("fonts/GiestMono/GeistMono-Bold.otf");
  font-weight: bold;
  font-style: normal;
}

/* @font-face {
  font-family: UnisonPro;
  src: url("fonts/UnisonPro/Type\ Juice\ -\ Unison\ Pro\ Light.otf");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: UnisonPro;
  src: url("fonts/UnisonPro/Type\ Juice\ -\ Unison\ Pro\ Bold.otf");
  font-weight: 700;
  font-style: normal;
} */

@font-face {
  font-family: HankenGrotesk;
  src: url("fonts/HankenGrotesk/HankenGrotesk-VariableFont_wght.ttf")
    format("woff2");
  font-weight: 100 900; /* Define the font's range */
  font-stretch: 75% 125%;
  font-style: normal;
}

@font-face {
  font-family: PerfectlyNineties;
  src: url("fonts/PerfectlyNineties/perfectly-nineties-regular.otf");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: PerfectlyNineties;
  src: url("fonts/PerfectlyNineties/perfectly-nineties-regular-italic.otf");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: PerfectlyNineties;
  src: url("fonts/PerfectlyNineties/perfectly-nineties-semibold.otf");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: PerfectlyNineties;
  src: url("fonts/PerfectlyNineties/perfectly-nineties-semibold-italic.otf");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: PerfectlyNineties;
  src: url("fonts/PerfectlyNineties/perfectly-nineties-bold.otf");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: PerfectlyNineties;
  src: url("fonts/PerfectlyNineties/perfectly-nineties-bold-italic.otf");
  font-weight: 700;
  font-style: italic;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: Avenir; */

  letter-spacing: auto;
  /* overflow-x: hidden; */
}
html {
  overflow-x: hidden;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Roboto Mono" sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  display: none;
}

::-webkit-scrollbar-track {
  display: none;
}
:root {
  --bg1: #fff;
  --bg2: #f5f5f5;
  --bg3: #f0c5ab;
  --bg4: #f1bde7;
  --bg5: #f3f3f3;
  --fg1: #1c1b1f;
  --fg2: #000000bf;
  --accent: #5e2bff;
  --text1: #0f0f0f;
  --text2: #1f1f1f;
  --text3: #5a5a5a;
  --border: #cbcbcb;

  /*Fonts*/
  --font-sans: "HankenGrotesk";
  --font-serif: "PPAgrandir";
  --font-mono: "GeistMono";
  --font-script: "PerfectlyNineties";

  /* Text Styles*/

  --display1-size: 48px;
  --display1-weight: 900;
  --display2ex-size: 42px;
  --display2ex-weight: 700;
  --display3-size: 36px;
  --display3-weight: 700;

  --heading1-size: 24px;
  --heading1-weight: 500;
  --heading2-size: 20px;
  --heading2-weight: 700;

  --title-size: 16px;
  --title-weight: 600;
  --subtitle-size: 12px;
  --subtitle-weight: 700;

  --label-size: 14px;
  --label-weight: 500;
  --labelex-size: 18px;
  --labelex-weight: 500;

  --body-size: 16px;
  --body-weight: 400;
  --body-line-height: 200%;
  --body-letter-spacing: -0.04em;

  --docHeading1-size: 28px;
  --docHeading2-size: 20px;
  --docHeading2-line-height: 150%;

  --docBody-size: 16px;
  --docBody-line-height: 150%;

  --docList-size: 16px;
  --docList-line-height: 150%;

  --doc-Caption: 14px;
  --doc-Subtitle: 14px;
}
